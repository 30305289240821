import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { uniq, take, orderBy } from "lodash";
import styled from "styled-components";
import { Container } from "../../components/layouts/components/Container";
import Layout from "../../components/layouts";
import { CaseStudy } from "../../components/case-study";
import ButtonLargePrimaryComponent from "../../components/layouts/components/ButtonLargePrimaryComponent";
import FormPanelDesktopComponent from "../../components/layouts/FormPanelDesktopComponent";
import { LustriaH1 } from "../../components/text";
import SEO from "../../templates/seo";
import { canonicalUrlResolver } from "../../utils/routeHelper";

export const query = graphql`
  query caseStudiesPageQuery {
    prismic {
      page(lang: "en-au", uid: "case-studies") {
        seo {
          seo_description
          seo_image
          seo_title
          keywords
          business_information {
            ... on PRISMIC_Business_information {
              business_name
              logo
              address
              city
              country
              description
              state_region
              telephone
              url
              zip
            }
          }
        }
      }
      allCase_studys {
        edges {
          node {
            title
            author_name
            published_date
            featured_image
            is_featured_case_study
            subtitle
            _linkType
            _meta {
              tags
              uid
            }
          }
        }
      }
    }
  }
`;

const PAGINATION_MAX = 8;
const ALL_TAG = "All";
const MAX_LIST_TAG_LENGTH = 4;

const handleDestructure = ({ prismic }) => {
  const CaseStudies = prismic.allCase_studys.edges.map((x) => {
    const { _meta, ...rest } = x.node;

    return {
      ...rest,
      slug: _meta.uid,
      tags: _meta.tags,
    };
  });

  const allTags = CaseStudies.flatMap((x) => x.tags);
  const tags = uniq(allTags);

  return {
    tags,
    caseStudyPosts: CaseStudies,
  };
};

const TagDropdown = ({ selectedTag, handleTagDropdownChange, allTags }) => (
  <TagDropDown
    className="form-select"
    value={selectedTag}
    onChange={handleTagDropdownChange}
  >
    {allTags.map((x) => (
      <option key={x} value={x}>
        {x === ALL_TAG ? "All Categories" : x}
      </option>
    ))}
  </TagDropDown>
);

const CaseStudies = ({ data }) => {
  const seo = data.prismic.page?.seo;
  const [CaseStudies, setCaseStudies] = useState([]);
  const [featuredCaseStudy, setFeaturedCaseStudy] = useState(undefined);
  const [totalCaseStudies, setTotalCaseStudies] = useState(0);
  const [totalDisplayedCaseStudies, setTotalDisplayedCaseStudies] = useState(
    PAGINATION_MAX
  );
  const [selectedTag, setSelectedTag] = useState(ALL_TAG);
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    const { caseStudyPosts, tags } = handleDestructure(data);

    const b = [...caseStudyPosts];

    const filteredCaseStudies =
      selectedTag === ALL_TAG
        ? b
        : b.filter((x) => x.tags.includes(selectedTag));

    const featuredCaseStudy =
      selectedTag === ALL_TAG
        ? filteredCaseStudies.find((x) => x.is_featured_CaseStudy)
        : null;

    const otherCaseStudies = filteredCaseStudies.filter(
      (x) => selectedTag !== ALL_TAG || !x.is_featured_CaseStudy
    );

    setTotalCaseStudies(filteredCaseStudies.length);
    setAllTags([ALL_TAG, ...tags]);

    setFeaturedCaseStudy(featuredCaseStudy);
    setCaseStudies(orderBy(otherCaseStudies, "published_date", "desc"));
  }, [selectedTag, data]);

  const handleTagChange = (tag) => () => {
    setSelectedTag(tag);
    setTotalDisplayedCaseStudies(PAGINATION_MAX);
  };

  const handleTagDropdownChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const showMore = () => {
    setTotalDisplayedCaseStudies(totalDisplayedCaseStudies + PAGINATION_MAX);
  };

  const contentTitle = "Case Studies";

  const url = canonicalUrlResolver("/case-studies")

  return (
    <Layout canonicalUrl={url}>
      {seo && <SEO seo={seo} siteUrl={data.site?.siteMetadata?.siteUrl} />}
      <ContentContainer aria-label={contentTitle}>
        <CaseStudiesHolder>
          <HeaderDesktop
            className={
              totalCaseStudies > MAX_LIST_TAG_LENGTH
                ? "d-lg-flex align-items-center justify-content-between"
                : ""
            }
          >
            <Title>{contentTitle}</Title>
            {totalCaseStudies <= MAX_LIST_TAG_LENGTH ? (
              <TagsHolder>
                {allTags.map((t, index) => (
                  <Tags
                    onClick={handleTagChange(t)}
                    isLast={index === allTags.length - 1}
                    active={selectedTag === t}
                  >
                    {t}
                  </Tags>
                ))}
              </TagsHolder>
            ) : (
              <div>
                <TagDropdown
                  allTags={allTags}
                  handleTagDropdownChange={handleTagDropdownChange}
                  selectedTag={selectedTag}
                />
              </div>
            )}
          </HeaderDesktop>
          <HeaderMobile>
            <Title>Case Studies</Title>
            <div>
              <TagDropdown
                allTags={allTags}
                handleTagDropdownChange={handleTagDropdownChange}
                selectedTag={selectedTag}
              />
            </div>
          </HeaderMobile>
          {featuredCaseStudy ? (
            <div>
              <FeaturedCaseStudyHolder>
                <CaseStudy {...featuredCaseStudy} isFeatured={true} />
              </FeaturedCaseStudyHolder>
              <FeaturedCaseStudyHolderMobile>
                <div>
                  <CaseStudy {...featuredCaseStudy} />
                </div>
              </FeaturedCaseStudyHolderMobile>
            </div>
          ) : (
            <></>
          )}
          <div className="row">
            {take(CaseStudies, totalDisplayedCaseStudies).map((x) => (
              <CaseStudyHolder className="col-12 col-md-6">
                <CaseStudy {...x} />
              </CaseStudyHolder>
            ))}
          </div>
        </CaseStudiesHolder>

        {totalDisplayedCaseStudies < CaseStudies.length ? (
          <ShowMore onClick={showMore}>Show More</ShowMore>
        ) : (
          <></>
        )}
      </ContentContainer>
      <FooterWrapper>
        <FormPanelDesktopComponent />
      </FooterWrapper>
    </Layout>
  );
};

const Title = styled(LustriaH1)`
  text-align: center;
`;

const FooterWrapper = styled.div`
  padding-bottom: 48px;
`;

const ContentContainer = styled(Container)`
  margin-top: 48px;
  padding: 0 24px 24px;

  @media (min-width: 992px) {
    padding: 0 0 48px;
  }
`;

const TagsHolder = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

const Tags = styled.button`
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: Helvetica;
  font-size: 20px;

  ${({ active }) =>
    active &&
    `border-bottom: 4px solid ${({ theme }) =>
      theme.colors.lightButtonPressed}; 
    font-weight: bold;
  `};
  ${({ isLast }) => !isLast && "margin-right: 16px"};
`;

const CaseStudiesHolder = styled.div`
  @media (min-width: 992px) {
    padding: 0 200px;
  }
`;

const CaseStudyHolder = styled.div`
  margin-bottom: 24px;
`;
const FeaturedCaseStudyHolder = styled(CaseStudyHolder)`
  display: none;
  margin: 0 24px 24px;

  width: 100% !important;

  @media (min-width: 992px) {
    padding: 0;
    margin: 0 0 48px;
    display: block;
  }
`;

const FeaturedCaseStudyHolderMobile = styled(FeaturedCaseStudyHolder)`
  display: block;
  margin: 0 auto 24px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const HeaderDesktop = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }

  margin-bottom: 60px;
`;

const HeaderMobile = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  justify-content: space-between;

  @media (min-width: 992px) {
    display: none;
  }

  ${Title} {
    font-size: 32px;
    text-align: left;
  }
`;

const TagDropDown = styled.select`
  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: Helvetica;
  background-size: 24px;
  padding 14px 56px 10px 16px !important;
`;

const ShowMore = styled(ButtonLargePrimaryComponent)`
  margin: 0 auto;
`;

export default CaseStudies;
